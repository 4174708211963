import {
  lowerBreakpointDesktopPx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import { FormProps } from "./formContentElement";

interface FormContentElementStyleScopedProps extends FormProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 * @param props
 * @returns
 */
export const FormContentElementStyleScoped = (
  props: FormContentElementStyleScopedProps
) => {
  const belowInputPadding =
    props.ceSettings?.formFieldInputSpace?.belowInput ?? 0;
  const aboveInputPadding =
    props.ceSettings?.formFieldInputSpace?.aboveInput ?? 0;
  return (
    <style jsx global>
      {`
        :global(.${props.scopedSelector}) {
          .form-component {
            margin-left: auto;
            margin-right: auto;
            max-width: ${props.ceSettings?.maxWidth?.mobile
              ? props.ceSettings?.maxWidth?.mobile + "px"
              : "992px"};
            .form-field-wrapper {
              .form-field {
                padding-bottom: ${belowInputPadding}px;
              }
              .cms-input-label {
                padding-bottom: ${aboveInputPadding}px;
              }
            }

            @media (min-width: ${lowerBreakpointTabletPx}) {
              max-width: ${props.ceSettings?.maxWidth?.tablet
                ? props.ceSettings?.maxWidth?.tablet + "px"
                : "992px"};
            }

            @media (min-width: ${lowerBreakpointDesktopPx}) {
              max-width: ${props.ceSettings?.maxWidth?.desktop
                ? props.ceSettings?.maxWidth?.desktop + "px"
                : "992px"};
            }

            @media (min-width: ${lowerBreakpointWqhdPx}) {
              max-width: ${props.ceSettings?.maxWidth?.wqhd
                ? props.ceSettings?.maxWidth?.wqhd + "px"
                : "992px"};
            }
          }
        }
      `}
    </style>
  );
};
