import ResponseResult from "@/types/classes/ResponseResult";
import { PbForm } from "@/types/pbForm/pbForm";
import { getNextJsApiURL } from "@/utils/api";
import {
  axiosGetRequestClientSide,
  axiosPostRequestClientSide,
} from "@/utils/axiosClientUtil";
import { translate } from "@/utils/localizationUtil";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";

export const getFormById = async (
  formId: number
): Promise<ResponseResult<PbForm>> => {
  const result: ResponseResult<PbForm> = await axiosGetRequestClientSide(
    getNextJsApiURL(`/content/pabu/forms/${formId}`)
  );
  if (!result.success) {
    global.log.warn("could not fetch form");
  }
  return result;
};

export const submitForm = async (
  data: any
): Promise<ResponseResult<boolean>> => {
  const result: ResponseResult<boolean> = await axiosPostRequestClientSide(
    getNextJsApiURL(`/forms/submit`),
    data
  );
  if (result.success) {
    if (result.data) {
      createToastSuccess(translate("public:formSubmitSuccess"));
    } else {
      createToastError(translate("public:formValidationError"));
    }
  } else {
    createToastError(translate("public:formSubmitError"));
  }
  return result;
};

/**
 * Please don't add more validations.
 * Because you would need to re-test every formField and validation that uses this method.
 * You can for example add a new method with your own validation.
 *
 * Checks given value string against all selected checks.
 * Make sure to always sync validation-Changes with backend!
 * @param {String} value the value to check
 * @param {Boolean} checkMandatory is input field mandatory
 * @param {Boolean} checkNoSpecialCharacters
 * @param {Boolean} checkNoLetters
 * @param {Boolean} checkNoNumbers
 * @param {Number} checkMaxChars
 * @param {Number} checkMinChars
 * @param {String} checkCustomRegex regex string must be without beginning and ending escape slash e.g. "/^[5]+$/" => "^[5]+$"
 * @param {Boolean} checkEmail
 * @param {Boolean} checkCheckbox
 * @param {Boolean} checkNoWhitespaceCharacters
 * @returns true if all selected checks are fullfilled, else false
 */
export const validateFormInput = (
  value: string,
  {
    checkMandatory,
    checkNoSpecialCharacters,
    checkNoLetters,
    checkNoNumbers,
    checkMaxChars,
    checkMinChars,
    checkCustomRegex,
    checkEmail,
    checkCheckbox,
    checkNoWhitespaceCharacters,
  }: {
    checkMandatory: boolean;
    checkNoSpecialCharacters: boolean;
    checkNoLetters: boolean;
    checkNoNumbers: boolean;
    checkMaxChars?: number;
    checkMinChars?: number;
    checkCustomRegex?: RegExp;
    checkEmail: boolean;
    checkCheckbox: boolean;
    checkNoWhitespaceCharacters: boolean;
  }
) => {
  // mandatory check
  if (checkMandatory) {
    if (value === null || value.length < 1) {
      return false;
    }
  }
  // no letters
  if (checkNoLetters) {
    // Make sure to always sync validation-Changes with backend!
    if (value && /[a-zA-ZÄÖÜäöüß]/g.test(value.trim())) {
      return false;
    }
  }
  // no special characters except whitespace
  if (checkNoSpecialCharacters) {
    // Make sure to always sync validation-Changes with backend!
    if (value && /^[a-z A-ZÄÖÜäöüß\d]+$/.test(value.trim()) === false) {
      return false;
    }
  }
  // no whitespace (no .trim() is applied)
  if (checkNoWhitespaceCharacters) {
    // Make sure to always sync validation-Changes with backend!
    if (value && /^\S*$/.test(value) === false) {
      return false;
    }
  }
  // only numbers
  if (checkNoNumbers) {
    // Make sure to always sync validation-Changes with backend!
    if (value && /\d/g.test(value.trim())) {
      return false;
    }
  }
  // max chars
  if (checkMaxChars) {
    if (value && value.length > checkMaxChars) {
      return false;
    }
  }
  // min chars
  if (checkMinChars) {
    if (value && value.length < checkMinChars) {
      return false;
    }
  }
  // custom regex
  if (value && checkCustomRegex) {
    const customRegEx = RegExp(checkCustomRegex);
    if (customRegEx.test(value) === false) {
      return false;
    }
  }

  // email
  if (value && checkEmail) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(value) === false) {
      return false;
    }
  }
  // checkbox
  if (checkCheckbox) {
    if (checkMandatory && !value) {
      return false;
    }
  }
  return true;
};

export const validateFormFileInput = (
  file: any,
  checkMandatory: boolean,
  cfgFieldAllowedFileEndings: string,
  maxFormFileSize: number
) => {
  const formField = {
    isValid: true,
    value: null,
  };
  const warnings = [];

  if (checkMandatory) {
    if (file === null) {
      formField.isValid = false;
    }
  }

  if (file) {
    // fileSize-Check
    if (file.size > (maxFormFileSize + 0.01) * 1024 * 1024) {
      formField.isValid = false;
      warnings.push(
        translate("public:fileNeedsToBeSmallerThan", {
          maxFileSize: maxFormFileSize,
        })
      );
    }

    // fileExtension-Check: Removing whitespaces in cfgFieldAllowedFileEndings
    const allowedFileEndings = cfgFieldAllowedFileEndings.replace(/\s/g, "");
    if (allowedFileEndings.length > 0 && file.name) {
      const allowedFileEndingsArray = allowedFileEndings
        .split(",")
        .filter((e) => e !== "");
      if (allowedFileEndingsArray.length > 0) {
        // Check for matching of name.jpg with ['.jpg', '.pdf']
        if (
          !allowedFileEndingsArray.includes(
            file.name.substring(file.name.lastIndexOf("."))
          )
        ) {
          // accepted Size & accepted fileEnding
          formField.isValid = false;
          warnings.push(
            translate("public:invalidFileFormat", {
              allowedFileEndings: allowedFileEndings,
            })
          );
        }
      }
    } else {
      // No allowedFileEndings provided!
      formField.isValid = false;
      warnings.push(
        translate("public:invalidFileFormat", {
          allowedFileEndings: allowedFileEndings,
        })
      );
      global.log.info("No allowedFileEndings provided!");
    }
  }
  return { formField, warnings };
};
