import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useTranslation } from "next-i18next";
import ReCaptcha from "./recaptcha/reCaptcha";

export interface CaptchaProps {
  hintText?: string;
  showHint: boolean;
  hintTextClassName?: string;
}


/**
 * Used to display a captcha.
 * @returns
 */
export default function Captcha(props: CaptchaProps) {
  const { t: tPublic } = useTranslation("public");
  const CAPTCHA_TYPE = globalConfig?.captchatype?.formCaptchaType ?? "none";
  const renderCaptcha = () => {
    switch (CAPTCHA_TYPE) {
      case "googlerecaptchav2":
        return (
          <ReCaptcha
            hintText={tPublic("fillOutCaptcha")}
            showHint={props.showHint}
            hintTextClassName="captcha-hint"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {renderCaptcha()}
      <style jsx>{`
        :global(.captcha-hint) {
          color: red;
        }
      `}</style>
    </>
  );
}
