import CmsForm from "@/components/cms/cmsForm/cmsForm";
import useIsMounted from "@/hooks/useIsMounted";
import useScopedClassName from "@/hooks/useScopedClassName";
import { getFormById } from "@/services/form/formService";
import { useAppSelector } from "@/store/store";
import { ContentElementFormStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEForm } from "@/types/content-elements";
import { PbForm } from "@/types/pbForm/pbForm";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import FormContentElementStyleGlobal from "./formContentElementStyleGlobal";
import { FormContentElementStyleScoped } from "./formContentElementStyleScoped";

export interface FormProps {
  content: CEForm;
  position: number;
  ceSettings?: ContentElementFormStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const FORM_CE_NAME = "formCE";

export default React.memo(function FormContentElement(props: FormProps) {
  const FORM_SCOPED_CLASS = useScopedClassName(
    FORM_CE_NAME,
    props.content,
    props.position
  );

  const [form, setForm] = useState<PbForm | null>(null);
  const { cfgSelectFormId } = props.content;
  const isMounted = useIsMounted();
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);

  // fetching the actual form
  useEffect(() => {
    const formId = cfgSelectFormId;
    if (formId) {
      (async () => {
        const requestedForm = await getFormById(formId);
        if (isMounted()) {
          if (requestedForm.success) {
            setForm(requestedForm.data);
          } else {
            setForm(null);
          }
        }
      })();
    } else {
      setForm(null);
    }
  }, [isMounted, cfgSelectFormId]);

  return (
    <>
      <ContentElementWrapper
        name={FORM_CE_NAME}
        scopedClassName={FORM_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div className={clsx(FORM_CE_NAME, FORM_SCOPED_CLASS)}>
          {form || editMode ? (
            <Col xs={12} className="form-body">
              <div className="form-component">
                <CmsForm
                  form={form}
                  position={props.position}
                  ceSettings={props.ceSettings}
                  content={props.content}
                />
              </div>
            </Col>
          ) : null}
        </div>
      </ContentElementWrapper>
      <FormContentElementStyleGlobal {...props} />
      <FormContentElementStyleScoped
        scopedSelector={FORM_SCOPED_CLASS}
        {...props}
      />
    </>
  );
});
