import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { Col, Row } from "react-bootstrap";

export interface CmsCaptchaPlaceholderProps {}

const CmsCaptchaPlaceholder = (props: CmsCaptchaPlaceholderProps) => {
  const tCms = useCmsTranslation();
  const CAPTCHA_TYPE = globalConfig?.captchatype?.formCaptchaType ?? "none";
  return (
    <>
      <Row className="edge-to-edge-row">
        <Col xs={6} md={4} className="my-3">
          <div
            className="py-2 captcha-placeholder"
            style={{ border: "1px dashed black" }}
          >
            <PbIcon
              name="square-check-light"
              width={55}
              height={55}
              className="svg-fill-black"
            />
            Captcha {tCms("placeholder")}
          </div>
        </Col>
      </Row>
      {CAPTCHA_TYPE === "none" && (
        <span style={{ color: "red" }}>
          {tCms("form-globalFormConfigIsNone")}
        </span>
      )}
    </>
  );
};

export default CmsCaptchaPlaceholder;
