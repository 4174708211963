const CmsFormStyle = () => {
  return (
    <style jsx global>
      {`
        :global(.captcha-placeholder img) {
          transform: scale(0.7);
          transform-origin: 0 0;
          cursor: not-allowed;
          float: left;
        }

        .cms-form {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
        .cms-form h3 {
          text-align: center;
        }

        .form-field.checkbox-form-field {
          display: flex;
          flex-direction: row;
        }

        .checkbox-form-field-label-wrapper {
          display: flex;
          margin-top: auto;
          margin-bottom: auto;
        }

        .checkbox-form-field-checkbox-wrapper {
          display: flex;
          max-height: 48px;
          margin-top: auto;
          margin-bottom: auto;
        }
        .checkbox-form-field .MuiCheckbox-root {
          margin-top: 0px !important;
          margin-left: -13px !important;
        }
        .checkbox-form-field-label {
          margin-bottom: 0 !important;
        }
        .checkbox-form-field-label.cms-input-label {
          padding-bottom: 0 !important;
        }

        /* Color neutral placeholder */
        .form-field .MuiInputBase-input::placeholder {
          color: inherit;
          filter: brightness(0.9);
        }

        .form-field .Mui-disabled .MuiInputBase-input {
          cursor: not-allowed;
        }
        span.mandatory-field {
          color: red;
        }

        .form-field .field-warning {
          color: red !important;
        }

        .cms-form .submit-successful {
          margin-top: 2rem;
          margin-bottom: 2rem;
          text-align: center;
          padding: 3rem;
          word-break: break-word;
        }

        @media (min-width: 567px) {
          :global(.captcha-placeholder img) {
            transform: scale(1);
            transform-origin: unset;
            cursor: not-allowed;
          }
        }
        :global(.url-field-wrapper) {
          position: absolute;
          left: -9999px;
        }
      `}
    </style>
  );
};

export default CmsFormStyle;
