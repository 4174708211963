
import { FORM_CE_NAME, FormProps } from "./formContentElement";

/**
 * This is the global style of a CE. 
 * This style is applied on all instances of this CE
 * 
 * @param props 
 * @returns 
 */
export default function FormContentElementStyleGlobal(props: FormProps) {
  return (
    <style jsx global>
      {`
        :global(.${FORM_CE_NAME}) {
        }
      `}
    </style>
  );
}
