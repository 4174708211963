import { globalConfig } from "../globalConfig/globalConfigService";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

/**
 * If it fails, the captcha hasn't been solved yet.
 * @param {*} captcha current captcha state
 * @returns "" -> (captcha not solved) | reCaptcha response
 */
export const checkCaptcha = () => {
  let captchaResponse = "";
  try {
    if (typeof window !== "undefined" && window.grecaptcha.getResponse()) {
      captchaResponse = window.grecaptcha.getResponse();
      return captchaResponse;
    }
  } catch (error) {
    return "";
  }
};

export const isCaptchaActivated = () => {
  if (globalConfig?.captchatype.formCaptchaType === "googlerecaptchav2") {
    return true;
  }
  return false;
};
