import { globalConfig } from "@/services/globalConfig/globalConfigService";
import clsx from "clsx";
import { useEffect } from "react";
import { CaptchaProps } from "../captcha";

/**
 * Google ReCaptchav2 Component
 * it is recommended to use only one recaptcha per view.
 *
 * How to use:
 * - add this component to your form if needed
 * - check with 'window.grecaptcha.getResponse()' if the captcha is filled if not prevent submit of form
 * - add window.grecaptcha.getResponse() as a parameter to your form
 */
function ReCaptcha(props: CaptchaProps) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const reCaptchaSiteKey = globalConfig?.recaptchaV2?.publicKey ?? "key is undefined";

  return (
    <>
      <div>
        <div className="g-recaptcha" data-sitekey={reCaptchaSiteKey}></div>
        <span
          id="captchaError"
          className="alert alert-danger col-sm-4"
          style={{ display: "none" }}
        ></span>
        {props.hintText && props.showHint && (
          <label className={clsx(props.hintTextClassName)}>
            {props.hintText}
          </label>
        )}
      </div>
      <style jsx>{`
        @media (min-width: 0px) {
          .g-recaptcha {
            transform: scale(0.7);
            transform-origin: 0 0;
          }
        }

        @media (min-width: 567px) {
          .g-recaptcha {
            transform: scale(1);
            transform-origin: unset;
          }
        }
      `}</style>
    </>
  );
}

export default ReCaptcha;
