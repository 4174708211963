import PbInputLabel from "@/components/input/pbInputLabel/pbInputLabel";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useStrTypography from "@/hooks/useStrTypography";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { ContentElementFormStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEForm } from "@/types/content-elements";
import {
  FormFieldBoolean,
  FormFieldDate,
  FormFieldEmail,
  FormFieldEnum,
  FormFieldFile,
  FormFieldString,
  FormFieldText,
} from "@/types/pbForm/pbForm";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { Col, Row } from "react-bootstrap";

const PbRichTextView = dynamic(
  () => import("@/components/pbRichTextView/pbRichTextView")
);
const CmsCheckboxFormField = dynamic(
  () => import("./cmsFormFields/cmsCheckboxFormField/cmsCheckboxFormField")
);
const CmsDateFormField = dynamic(
  () => import("./cmsFormFields/cmsDateFormField/cmsDateFormField")
);
const CmsDropdownFormField = dynamic(
  () => import("./cmsFormFields/cmsDropdownFormField/cmsDropdownFormField")
);
const CmsEmailFormField = dynamic(
  () => import("./cmsFormFields/cmsEmailFormField/cmsEmailFormField")
);
const CmsFileFormField = dynamic(
  () => import("./cmsFormFields/cmsFileFormField/cmsFileFormField")
);
const CmsStringFormField = dynamic(
  () => import("./cmsFormFields/cmsStringFormField/cmsStringFormField")
);
const CmsTextFormField = dynamic(
  () => import("./cmsFormFields/cmsTextFormField/cmsTextFormField")
);

export interface CmsFormFieldRendererProps {
  formField:
    | FormFieldString
    | FormFieldEmail
    | FormFieldBoolean
    | FormFieldText
    | FormFieldFile
    | FormFieldDate
    | FormFieldEnum;
  index: number;
  isEditor: boolean;
  updateFormData: (validatedFieldData: any) => void;
  content?: CEForm;
  ceSettings?: ContentElementFormStoreSetting;
}

const CmsFormFieldRenderer = (props: CmsFormFieldRendererProps) => {
  const { richTextClassName } = useCesStrRichText(
    globalConfig?.forms?.richtext
  );

  const { typographyClassName: inputLabelClassname } = useStrTypography(
    props.content?.cfgStrInnerTypographyInputLabelTypography,
    props.ceSettings?.inputLabelTypography
  );

  const { typographyClassName: inputClassname } = useStrTypography(
    props.content?.cfgStrInnerTypographyInputTypography,
    props.ceSettings?.inputTypography
  );

  const renderFormField = (
    formField:
      | FormFieldString
      | FormFieldEmail
      | FormFieldBoolean
      | FormFieldText
      | FormFieldFile
      | FormFieldDate
      | FormFieldEnum,
    index: number,
    isEditor: boolean,
    updateFormData: (validatedFieldData: any) => void
  ) => {
    switch (formField.__component) {
      case "pb.frmstr":
        return (
          <>
            {/* StringField */}
            {formField.fieldLabel && (
              <PbInputLabel
                label={formField.fieldLabel}
                mandatory={formField.cfgFieldIsMandatory}
                className={inputLabelClassname}
              />
            )}
            <CmsStringFormField
              errorTypographyClassName={inputLabelClassname}
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </>
        );
      case "pb.frmml":
        return (
          <>
            {/* EmailField */}
            {formField.fieldLabel && (
              <PbInputLabel
                label={formField.fieldLabel}
                mandatory={formField.cfgFieldIsMandatory}
                className={inputLabelClassname}
              />
            )}
            <CmsEmailFormField
              errorTypographyClassName={inputLabelClassname}
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </>
        );
      case "pb.frmnm":
        return (
          <>
            {/* DropdownField (Autocomplete) */}
            {formField.fieldLabel && (
              <PbInputLabel
                label={formField.fieldLabel}
                mandatory={formField.cfgFieldIsMandatory}
                className={inputLabelClassname}
              />
            )}
            <CmsDropdownFormField
              className={inputClassname}
              errorTypographyClassName={inputLabelClassname}
              fullWidth
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </>
        );
      case "pb.frmtxt":
        return (
          <>
            {/* TextField multiline*/}
            {formField.fieldLabel && (
              <PbInputLabel
                label={formField.fieldLabel}
                mandatory={formField.cfgFieldIsMandatory}
                className={inputLabelClassname}
              />
            )}
            <CmsTextFormField
              errorTypographyClassName={inputLabelClassname}
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </>
        );
      case "pb.frmbl":
        return (
          <Row>
            <Col xs={12}>
              <PbRichTextView
                className={clsx(
                  richTextClassName,
                  "checkbox-form-field",
                  inputClassname
                )}
                htmlString={formField.fieldRichTextDescription}
              />
            </Col>
            <Col xs={12}>
              <CmsCheckboxFormField
                className={inputClassname}
                formField={formField}
                isEditor={isEditor}
                formPos={index}
                handleValidatedInput={(validatedFieldData) =>
                  updateFormData(validatedFieldData)
                }
                label={formField.fieldLabel}
                mandatory={formField.cfgFieldIsMandatory}
                inputLabelClassname={inputLabelClassname}
              />
            </Col>
          </Row>
        );
      case "pb.frmfl":
        return (
          <>
            {/* FileField */}
            {formField.fieldLabel && (
              <PbInputLabel
                label={formField.fieldLabel}
                mandatory={formField.cfgFieldIsMandatory}
                className={inputLabelClassname}
              />
            )}
            <CmsFileFormField
              errorTypographyClassName={inputLabelClassname}
              content={props.content}
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
              ceSettings={props.ceSettings}
            />
          </>
        );
      case "pb.frmdt":
        return (
          <>
            {/* DateField */}
            {formField.fieldLabel && (
              <PbInputLabel
                label={formField.fieldLabel}
                mandatory={formField.cfgFieldIsMandatory}
                className={inputLabelClassname}
              />
            )}
            <CmsDateFormField
              className={inputClassname}
              errorTypographyClassName={inputLabelClassname}
              formField={formField}
              isEditor={isEditor}
              formPos={index}
              handleValidatedInput={(validatedFieldData) =>
                updateFormData(validatedFieldData)
              }
            />
          </>
        );
      default:
        return <pre>{JSON.stringify(formField)}</pre>;
    }
  };
  return (
    <Row className="form-field-renderer edge-to-edge-row">
      <Col xs={12}>
        {renderFormField(
          props.formField,
          props.index,
          props.isEditor,
          props.updateFormData
        )}
      </Col>
    </Row>
  );
};

export default CmsFormFieldRenderer;
